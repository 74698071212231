import HomeHero from '@ui/patterns/HomeHero';
import DevTools from '@ui/modules/DevTools';
import { FAQ } from '@ui/modules/Faq';
import { Footer } from '@app/domain/platform/templates/Footer.tsx';
import { Tools } from '@app/domain/information/templates/Tools';
import { Link } from '@ui/components/Link';
import cloudImage from '@assets/seee.jpg';
import heroImage from '@assets/group_33.png';
import ExxetaBrain from '@assets/exxeta_brain-circuit_white.png';
import { InformationText } from '@ui/patterns/InformationText/InformationText';

export const HomePage: React.FC = () => {
    return (
        <>
            <HomeHero
                heading={{ children: 'ai.transdev-vertrieb' }}
                text={{
                    children: 'Transformation durch KI-gesteuerte Innovation',
                }}
                casesLink={{ href: '#toolView', children: 'Cases' }}
                docsLink={{ href: '/api-reference', children: 'Doku' }}
                backgroundImage={{ src: cloudImage }}
                heroImage={{ src: heroImage, alt: 'Hero Image' }}
            />
            <InformationText
                text="Willkommen zu ai.transdev-vertrieb.com."
                answer="Wir nutzen Innovation."
                image={ExxetaBrain}
                isDarkBackground={true}
            />
            <Tools />
            <DevTools
                heading={{ children: 'Prompting' }}
                publicUrls={[
                    {
                        title: 'Wie man bessere ChatGPT-Prompts schreibt',
                        description:
                            'Beherrsche das Schreiben von Chatbot-Prompts für optimale KI-Ergebnisse: Verbessere deine Fähigkeiten im Schreiben von Prompts.',
                        link: 'https://www.zdnet.com/article/how-to-write-better-chatgpt-prompts/',
                    },
                    {
                        title: 'Tolle GPT-Prompts',
                        description:
                            'Entdecke hier eine Vielzahl von Prompts und trage gerne eigene bei oder erstelle neue Prompts mit ChatGPT.',
                        link: 'https://github.com/f/awesome-chatgpt-prompts',
                    },
                    {
                        title: 'OpenAI Kochbuch',
                        description: 'Beispiele und Anleitungen zur Nutzung der OpenAI-API',
                        link: 'https://github.com/openai/openai-cookbook/tree/main/examples',
                    },
                ]}
                text={{ children: 'Von Entwicklern, für Entwickler' }}
            />

            <FAQ
                heading={{ children: 'FAQ' }}
                questions={[
                    {
                        title: { children: 'Werden meine Daten zum Trainieren von Modellen verwendet?' },
                        children: 'Nein.',
                    },
                    {
                        title: {
                            children:
                                'Kann ich die transdev-vertrieb AI-Platform mit intern klassifizierten Daten nutzen?',
                        },
                        children: 'Ja.',
                    },
                    {
                        title: {
                            children: 'Kann ich die transdev-vertrieb AI-Platform mit Kundendaten nutzen?',
                        },
                        children: (
                            <>
                                Grundsätzlich ja, da die transdev-vertrieb AI-Platform eine lokale, isolierte Plattform
                                ist, die keine Daten zur Nachschulung verwendet. Der Vertrag mit dem Kunden ist jedoch
                                maßgebend. Außerdem gilt{' '}
                                <Link href="https://confluence.exxeta.network/display/UHEX/Policy_EXX_KI_Policy">
                                    die transdev KI-Richtlinie.
                                </Link>
                            </>
                        ),
                    },
                    {
                        title: {
                            children: 'Wird es eine Nutzungsbeschränkung geben, und wenn ja, wie wird diese aussehen?',
                        },
                        children: 'Ja. Wir wissen es aber noch nicht genau.',
                    },
                    {
                        title: {
                            children: 'Darf ich die Daten für meine produktive Arbeit nutzen?',
                        },
                        children:
                            'Nein. Die Platform dient als Sandbox zum Prototyping von Ideen und zur Präsentation von Anwendungsfällen für Kunden. Abhängig von der Entwicklung könnten wir Produktionsunterstützung in einer späteren Phase in Betracht ziehen.',
                    },
                    {
                        title: {
                            children:
                                'Ich habe eine interessante Idee, bin mir aber nicht sicher, ob es ein guter KI-Anwendungsfall ist – kann ich meine Idee mit euch besprechen?',
                        },
                        children: 'Natürlich – lassen Sie es uns einfach wissen.',
                    },
                ]}
            />

            <Footer />
        </>
    );
};
