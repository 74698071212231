import { CardInfo } from './NumberCard';
import { LineChart } from './LineChart';
import { CostChart } from './CostChart';
// import { AreaChartComponent } from "./AreaChart";
import { toMillion } from '@/lib/utils';
import { useAdminContext } from '../../context/AdminContextProvider';

export const Dashboard: React.FC = () => {
    const { totalStatistics, datasets } = useAdminContext();
    const activePercentage = Math.round((totalStatistics.activeUsers / totalStatistics.knownUsers) * 100);
    const knownPercentage = Math.round((totalStatistics.knownUsers / 1350) * 100);
    return (
        <div className="dashboard py-8">
            <h1 className="pt-10">Verbrauchs-Dashboard</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                <CardInfo
                    title="Verbrauch Gesamt"
                    value={toMillion(totalStatistics.totalTokens).toString() + 'M'}
                    subtitle="Tokens seit Launch verbraucht"
                />
                <CardInfo
                    title="Aktive User"
                    value={totalStatistics.activeUsers.toString()}
                    subtitle={`${activePercentage}% transdev-vertrieb Angestellte`}
                />
                <CardInfo
                    title="User Gesamt"
                    value={totalStatistics.knownUsers.toString()}
                    subtitle={`${knownPercentage}% transdev-vertrieb Angestellte`}
                />
                <CardInfo title="Apps Gesamt" value={totalStatistics.totalApps.toString()} subtitle="Apps erstellt" />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                <div className="lg:col-span-1"></div>
                <div className="lg:col-span-1" />
                <div className="lg:col-span-1">
                    <LineChart datasets={datasets} />
                </div>
                <div className="lg:col-span-1">
                    <CostChart />
                </div>
                {/*            <div className="lg:col-span-2">
                    <AreaChartComponent />
                </div>*/}
            </div>
        </div>
    );
};
