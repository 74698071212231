import React, { useEffect, useRef, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import logo from '@assets/transdev_mobility.svg';
import CloseIcon from '@mui/icons-material/Close';
import './Navbar.css';
import { Link, LinkProps } from '@ui/components/Link';

interface NavbarProps {
    burgerLinks: LinkProps[];
    pathname: string;
}
const Navbar = ({ burgerLinks, pathname }: NavbarProps) => {
    const [isNavCollapsed, setIsNavCollapsed] = useState(true);

    const [menuIconPosition, setMenuIconPosition] = useState({ top: 0, left: 0 });
    const navbarRef = useRef<HTMLDivElement>(null);

    const closeNavMobile = () => {
        setIsNavCollapsed(true);
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (navbarRef.current && !navbarRef.current.contains(event.target as Node)) {
                setIsNavCollapsed(true);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        setIsNavCollapsed(true);
    }, [pathname]);

    const toggleNavMobile = (event: React.MouseEvent<HTMLButtonElement>) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setMenuIconPosition({ top: rect.top, left: rect.left });
        setIsNavCollapsed(!isNavCollapsed);
    };

    return (
        <>
            {!isNavCollapsed && (
                <div
                    className="hidden sticky top-0 left-0 w-screen h-screen bg-black opacity-40 z-30"
                    style={{
                        backdropFilter: 'blur(5px)',
                        animation: 'blurredBackground 0.3s ease',
                    }}
                />
            )}
            <div className="fixed top-0 z-40 w-full 2xl:mx-auto bg-red" ref={navbarRef}>
                <div className="text-black flex container justify-between items-center mx-auto h-20">
                    <div className="flex-none pr-4">
                        <Link href="/">
                            <img src={logo} alt="TransdevLogo" className="h-12" />
                        </Link>
                    </div>
                    <div className="flex items-center z-40">
                        {isNavCollapsed && (
                            <button
                                onClick={(event) => toggleNavMobile(event)}
                                className="flex items-center py-2 px-5 ml-auto rounded-full text-white bg-red shadow-md"
                            >
                                <MenuIcon style={{ fontSize: '2rem' }}></MenuIcon>
                            </button>
                        )}
                    </div>
                </div>
                <div
                    style={{
                        top: menuIconPosition.top,
                        right: window.innerWidth - menuIconPosition.left - 90,
                    }}
                    className={`nav-mobile ${isNavCollapsed ? 'hidden' : 'visible'} bg-white p-4 text-black shadow-md`}
                >
                    <div className="flex justify-end mb-4">
                        <button onClick={closeNavMobile}>
                            <CloseIcon className="text-black" />
                        </button>
                    </div>
                    <div className="mb-4 bg-gray-300" style={{ height: '1px' }}></div>
                    <ul className="flex flex-col w-full text-left font-sen font-bold ">
                        {burgerLinks.map((navLink, index) => (
                            <Link
                                className="no-underline text-lg cursor-pointer bg-white hover:text-white hover:bg-red w-full p-2 rounded"
                                key={index}
                                {...navLink}
                            />
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
};

export default Navbar;
