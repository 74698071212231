import classNames from 'classnames';
import { useMemo } from 'react';

export type ButtonSize = 'small' | 'normal';
export type ButtonVariant = 'primary' | 'neutral' | 'subtle' | 'danger';
export type ButtonContext = 'dark' | 'bright';

export interface UseButtonStyleProps {
    size?: ButtonSize;
    variant?: ButtonVariant;
    context?: ButtonContext;
    disabled?: boolean;
}

export function useButtonStyle({ size = 'normal', variant, context = 'bright', disabled }: UseButtonStyleProps) {
    return useMemo(() => {
        const variantStyle = classNames({
            // Switched: Primary is now red
            'bg-[#FF0000] text-white': variant === 'primary' && context === 'bright',

            'hover:bg-[#CC0000]': variant === 'primary' && !disabled,

            'bg-white text-white': variant === 'primary' && context === 'dark',

            // Switched: Danger is now black
            'bg-black text-white': variant === 'danger' && context === 'bright',

            'hover:bg-white hover:text-black': variant === 'danger' && !disabled,

            // Neutral styles remain the same
            'bg-white text-black': variant === 'neutral' && context === 'bright',

            'hover:bg-black hover:text-white':
                (((variant === 'neutral' || variant === 'subtle') && context === 'bright') ||
                    (variant === 'danger' && context === 'dark')) &&
                !disabled,

            // Disabled styles
            'opacity-50 cursor-not-allowed': disabled,
        });

        return (
            variantStyle &&
            classNames(
                variantStyle,
                'inline-flex items-center justify-center font-sen-bold text-s', // Removed 'border-2'
                {
                    'h-12 px-8': size === 'normal',
                    'h-10 w-24': size === 'small',
                },
            )
        );
    }, [size, variant, context, disabled]);
}
