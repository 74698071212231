import {
    type ChartConfig,
    ChartContainer,
    ChartTooltip,
    ChartTooltipContent,
    ChartLegend,
    ChartLegendContent,
} from '@/components/ui/chart';
import { Bar, BarChart, CartesianGrid, XAxis } from 'recharts';
import { Card, CardHeader, CardTitle, CardDescription } from '@/components/ui/card';
import { ChartData } from '../../context/AdminContextProvider/AdminContext.types';

export interface LineChartProps {
    datasets: ChartData[];
}

const chartConfig = {
    dalle3: {
        label: 'Dall-E-3',
        color: 'hsl(var(--chart-1))',
    },
    gpt35turbo: {
        label: 'GPT-3.5-Turbo',
        color: 'hsl(var(--chart-2))',
    },
    gpt4o: {
        label: 'GPT-4o',
        color: 'hsl(var(--chart-3))',
    },
    gpt4omini: {
        label: 'Gpt-4o-mini',
        color: 'hsl(var(--chart-4))',
    },
    textembedding3large: {
        label: 'Text-Embedding-3-Large',
        color: 'hsl(var(--chart-5))',
    },
    textembeddingada002: {
        label: 'Text-Embedding-Ada-002',
        color: 'hsl(var(--chart-6))',
    },
} satisfies ChartConfig;

export function LineChart({ datasets }: LineChartProps) {
    const transformData = (data: ChartData[]): Record<string, any>[] => {
        return data.map((entry) => {
            const formattedEntry: Record<string, any> = { month: entry.month };
            for (const [modelId, tokens] of Object.entries(entry.models)) {
                formattedEntry[modelId.replace(/-/g, '')] = tokens;
            }
            return formattedEntry;
        });
    };

    return (
        <Card>
            <CardHeader>
                <CardTitle>Verbrauch pro Modell</CardTitle>
                <CardDescription>2024</CardDescription>
            </CardHeader>
            <ChartContainer config={chartConfig} className="min-h-[400px] w-full">
                <BarChart accessibilityLayer data={transformData(datasets)}>
                    <CartesianGrid vertical={false} />
                    <XAxis
                        dataKey="month"
                        tickLine={false}
                        tickMargin={10}
                        axisLine={false}
                        tickFormatter={(value) => value.slice(0, 3)}
                    />
                    <ChartTooltip content={<ChartTooltipContent />} />
                    <ChartLegend content={<ChartLegendContent />} />
                    <Bar dataKey="dalle3" fill="var(--color-dalle3)" radius={4} />
                    <Bar dataKey="gpt35turbo" fill="var(--color-gpt35turbo)" radius={4} />
                    <Bar dataKey="gpt4o" fill="var(--color-gpt4o)" radius={4} />
                    <Bar dataKey="gpt4omini" fill="var(--color-gpt4omini)" radius={4} />
                    <Bar dataKey="textembedding3large" fill="var(--color-textembedding3large)" radius={4} />
                    <Bar dataKey="textembeddingada002" fill="var(--color-textembeddingada002)" radius={4} />
                </BarChart>
            </ChartContainer>
        </Card>
    );
}
