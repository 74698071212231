import React, { useState, useEffect } from 'react';

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { AppsTable } from '../AppsTableModule';
import { UsersTable } from '../UsersTableModule';
import { UseCasesTable } from '../UseCasesTableModule/UseCasesTable';
import { useAdminContext } from '../../context/AdminContextProvider';

export const TableTabs: React.FC = () => {
    const { users, useCases, apps } = useAdminContext();
    const [searchQuery, setSearchQuery] = useState('');
    const [tabValue, setTabValue] = useState<string>('users');
    const [filteredApps, setFilteredApps] = useState(apps);
    const [filteredUseCases, setFilteredUsecases] = useState(useCases);
    const [filteredUsers, setFilteredUsers] = useState(users);

    const handleButtonClick = (tabValue: string) => {
        console.log(tabValue);
    };
    useEffect(() => {
        const filterItems = <T extends Record<string, any>>(items: T[], key: keyof T) => {
            if (!searchQuery) return items;
            return items.filter((item) => item[key].toLowerCase().includes(searchQuery.toLowerCase()));
        };
        setFilteredApps(filterItems(apps, 'name'));
        setFilteredUsecases(filterItems(useCases, 'title'));
        setFilteredUsers(filterItems(users, 'name'));
    }, [searchQuery, apps, users, useCases]);

    return (
        <div>
            <div className="table-section min-h-[1200px] pt-12">
                <div className="flex justify-between py-4">
                    <Tabs defaultValue="users" value={tabValue} onValueChange={setTabValue} className="w-full">
                        <div className="flex justify-between py-4">
                            <div className="w-1/3">
                                <Input
                                    type="text"
                                    placeholder="Namenssuche"
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                    className="w-1/2"
                                />
                            </div>
                            <div className="w-1/3 flex items-center justify-center">
                                <TabsList>
                                    <TabsTrigger value="users">User</TabsTrigger>
                                    <TabsTrigger value="apps">Apps</TabsTrigger>
                                    <TabsTrigger value="cases">Use Cases</TabsTrigger>
                                </TabsList>
                            </div>

                            <div className="w-1/3 flex items-center justify-end">
                                {!(tabValue === 'users' || tabValue === 'apps') && (
                                    <Button
                                        variant="default"
                                        onClick={() => handleButtonClick(tabValue)}
                                    >{`Add ${tabValue}`}</Button>
                                )}
                            </div>
                        </div>
                        <TabsContent value="users" className={tabValue !== 'users' ? 'hidden' : ''}>
                            <UsersTable data={filteredUsers} />
                        </TabsContent>
                        <TabsContent value="apps" className={tabValue !== 'apps' ? 'hidden' : ''}>
                            <AppsTable data={filteredApps} />
                        </TabsContent>
                        <TabsContent value="cases" className={tabValue !== 'cases' ? 'hidden' : ''}>
                            <UseCasesTable data={filteredUseCases} />
                        </TabsContent>
                    </Tabs>
                </div>
            </div>
        </div>
    );
};
