import React, { useEffect, useState } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';

interface AddAppDialogProps {
    isVisible: boolean;
    onClose: () => void;
    onAddApp: (userData: any) => void;
}

export const AddAppDialog: React.FC<AddAppDialogProps> = ({ isVisible, onClose, onAddApp }) => {
    const [formData, setFormData] = useState({
        app_name: '',
        project_number: '',
        description: '',
    });
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [isNameValid, setIsNameValid] = useState(false);

    // Validate app name on change
    const validateData = (name: string) => {
        setIsNameValid(name.length >= 5);
    };

    // Handle input changes
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        if (name === 'app_name') {
            validateData(value);
        }
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        if (!isNameValid) {
            setErrorMessage('App name must be at least 5 characters.');
            return;
        }
        try {
            onAddApp(formData); // Call the add function
            handleClose();
        } catch (error: any) {
            setErrorMessage(error.response?.data?.detail || 'An unexpected error occurred.');
        }
    };

    // Reset form data when the dialog is opened
    useEffect(() => {
        if (isVisible) {
            setFormData({
                app_name: '',
                project_number: '',
                description: '',
            });
            setErrorMessage(null); // Reset error message
            setIsNameValid(false); // Reset validation
        }
    }, [isVisible]);

    const handleClose = () => {
        setErrorMessage(null);
        onClose();
    };

    return (
        <Dialog open={isVisible} onOpenChange={onClose}>
            <DialogContent aria-describedby={undefined}>
                <DialogHeader>
                    <DialogTitle>App hinzufügen</DialogTitle>
                </DialogHeader>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="font-ubuntu block text-black mb-2">App Name:</label>
                        <input
                            type="text"
                            name="app_name"
                            value={formData.app_name}
                            onChange={handleChange}
                            placeholder="Name der App"
                            className="p-2 w-full border rounded bg-gray-100 border-gray-300"
                        />
                        {!isNameValid && <p className="text-red-500">Pflichtfeld. Benötigt mindestens 5 Zeichen.</p>}
                    </div>
                    <div className="mb-4">
                        <label className="font-ubuntu block text-black mb-2">Projektnummer:</label>
                        <input
                            type="text"
                            name="project_number"
                            value={formData.project_number}
                            onChange={handleChange}
                            placeholder="SAP Projektnummer (optional)"
                            className="p-2 w-full border rounded bg-gray-100 border-gray-300"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="font-ubuntu block text-black mb-2">Beschreibung:</label>
                        <input
                            type="text"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            placeholder="Beschreibe die App (optional)"
                            className="p-2 w-full border rounded bg-gray-100 border-gray-300"
                        />
                    </div>
                    {errorMessage && <div className="text-red-500 mb-4">{errorMessage}</div>}
                    <DialogFooter>
                        <Button type="submit" disabled={!isNameValid}>
                            Hinzufügen
                        </Button>
                        <Button type="button" variant="secondary" onClick={handleClose}>
                            Schließen
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
};
