import classNames from 'classnames';
export type LinkVariant = 'inline';

export interface UseLinkStyleProps {
    variant?: LinkVariant;
}

export function useLinkStyle({ variant }: UseLinkStyleProps) {
    return classNames({
        'font-ubuntu underline': variant === 'inline',
    });
}
