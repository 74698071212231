import { SectionFrame } from '@ui/components/SectionFrame';
import { Heading } from '@ui/components/Heading';
import { Text } from '@ui/components/Text';
import Card from '@ui/patterns/Card/Card';
import { Chip } from '@ui/components/Chip';
import { Link } from '@ui/components/Link';
import useTools from '@app/domain/admin/context/useTools';

export const Tools = () => {
    const { toolInfo } = useTools();

    return (
        <SectionFrame id="toolView">
            <div className="my-20">
                <Heading variant="h1">Verfügbare Tools</Heading>
                <Text>
                    Was schon alles auf der <Text bold>transdev-vertrieb AI Platform</Text> gebaut wurde.
                </Text>
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 gap-4">
                {toolInfo.map(({ image, title, short_description, tags, link, id }) => (
                    <Card key={id}>
                        <Card.BackgroundImage src={image} alt="" />
                        <Card.Title variant="h4">{title}</Card.Title>
                        <Card.Content>
                            <Text className="mb-2" size="s">
                                {short_description}
                            </Text>
                            {tags && (
                                <div className="my-4 flex gap-2 flex-wrap">
                                    {tags.map((tag) => (
                                        <Chip key={tag}>{tag}</Chip>
                                    ))}
                                </div>
                            )}
                        </Card.Content>
                        {link && (
                            <Card.Footer>
                                <Link href={link} target="_blank" buttonVariant="primary">
                                    Mehr
                                </Link>
                            </Card.Footer>
                        )}
                    </Card>
                ))}
            </div>
        </SectionFrame>
    );
};
