import { RootLayout } from '@app/domain/platform/templates/RootLayout';
import Router from './Router';
import { Toaster } from '@/components/ui/toaster';

function App() {
    return (
        <RootLayout
            authentication={{
                authority: 'https://login.microsoftonline.com/79e8676f-cb3f-45a6-8782-11185326340e',
                appScopes: ['api://13bfbcf2-fa19-4922-a2c2-10f8d72c5c9d/user_impersonation'],
                clientId: '13bfbcf2-fa19-4922-a2c2-10f8d72c5c9d',
                redirectUri: '/',
            }}
        >
            <Router />
            <Toaster />
        </RootLayout>
    );
}

export default App;
