import React from 'react';

type UserDataProps = {
    team: string;
    userId: string;
    position: string;
};

type UserInfoProps = {
    name: string;
    email?: string;
    isAdmin: boolean;
    accessToken?: string;
    userData?: UserDataProps;
};

// TODO refactor - move reusable markup to ui components
export const UserInfo: React.FC<UserInfoProps> = ({ name, email, isAdmin, userData }) => {
    return (
        <>
            <div className="max-w-full">
                <div className="w-full flex align-middle justify-center py-4">
                    <div className="rounded-full w-full  bg-black mb-4 flex items-center justify-center">
                        <img src="https://intranet.exxeta.network/img/fallback-image-big-squared-profile.jpeg"></img>
                    </div>
                </div>
                <table className="min-w-full font-ubuntu">
                    <tbody>
                        <tr>
                            <td className="px-4 py-2">
                                <h1 className="text-white bg-black p-1 w-auto inline-block font-ubuntu">
                                    {name.split(' ')[0].toUpperCase()}
                                </h1>
                                <h1 className="text-white bg-black p-1 mt-2 inline-block font-ubuntu">
                                    {name.split(' ')[1].toUpperCase()}
                                </h1>
                            </td>
                        </tr>
                        <tr>
                            <td className="px-4 text-xs">{isAdmin ? 'Admin' : 'User'}</td>
                        </tr>
                        <tr>
                            <td className="px-4">{email ? email : ''}</td>
                        </tr>
                        <tr>
                            <td className="px-4 pt-4">{userData ? userData.position : ''}</td>
                        </tr>
                        <tr>
                            <td className="px-4">{userData ? userData.team : ''}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    );
};
