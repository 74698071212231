import classNames from 'classnames';
import { PropsWithChildren } from 'react';

export interface SectionFrameProps extends PropsWithChildren {
    id?: string;
    isDarkBackground?: boolean;
    slim?: boolean;
    className?: string;
}

export function SectionFrame({ children, id, isDarkBackground, slim, className }: SectionFrameProps) {
    return (
        <section
            id={id}
            className={classNames(className, {
                'bg-darkGray': isDarkBackground,
            })}
        >
            <div
                className={classNames('container mx-auto', {
                    'py-16': !slim,
                })}
            >
                {children}
            </div>
        </section>
    );
}
