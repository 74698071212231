import React, { useEffect, useState } from 'react';
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
    DialogFooter,
    DialogDescription,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import axios from 'axios';

interface EditAppData {
    id: number;
    project_number: string;
    name: string;
    description: string;
}

interface EditDialogProps {
    isVisible: boolean;
    onClose: () => void;
    appToEdit: EditAppData;
    onEditApp: (formData: EditAppData) => void;
}

export const EditDialog: React.FC<EditDialogProps> = ({ isVisible, onClose, onEditApp, appToEdit }) => {
    const [formData, setFormData] = useState<EditAppData>({
        id: 0,
        project_number: '',
        name: '',
        description: '',
    });
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);

    useEffect(() => {
        setFormData({
            id: appToEdit.id,
            project_number: appToEdit.project_number ?? '',
            description: appToEdit.description ?? '',
            name: appToEdit.name,
        });

        setShowSuccessMessage(false);
        setErrorMessage(null);
    }, [appToEdit]);

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            onEditApp(formData);
            setShowSuccessMessage(true);
            setShowErrorMessage(false);
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                setErrorMessage(error?.response?.data?.detail);
            } else {
                setErrorMessage('An unexpected error occurred');
            }
            setShowErrorMessage(true);
            setShowSuccessMessage(false);
        }
    };

    const handleClose = () => {
        setShowSuccessMessage(false);
        setErrorMessage(null);
        setShowErrorMessage(false);
        setFormData({
            id: 0,
            project_number: '',
            description: '',
            name: '',
        });
        onClose();
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    return (
        <Dialog open={isVisible} onOpenChange={handleClose}>
            <DialogContent aria-describedby={undefined}>
                <DialogHeader>
                    <DialogTitle>'{appToEdit.name}' bearbeiten</DialogTitle>
                </DialogHeader>
                <DialogDescription>
                    Update Details für die ausgewählt App. Stelle sicher, dass alle Felder korrekt befüllt sind.
                </DialogDescription>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="font-ubuntu block text-black mb-2">Projektnummer:</label>
                        <input
                            type="text"
                            name="project_number"
                            value={formData.project_number}
                            onChange={handleChange}
                            className="p-2 w-full border rounded bg-gray-100 border-gray-300"
                        />
                    </div>
                    <div className="mb-4">
                        <label className="font-ubuntu block text-black mb-2">Beschreibung:</label>
                        <input
                            type="text"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            className="p-2 w-full border rounded bg-gray-100 border-gray-300"
                        />
                    </div>
                    {errorMessage && <div className="text-red-500">{errorMessage}</div>}
                    {showSuccessMessage && <div className="text-green-500">App updated successfully!</div>}
                    {showErrorMessage && <div className="text-red-500">Error updating app.</div>}
                    <DialogFooter>
                        <Button type="submit">Bearbeiten</Button>
                        <Button type="button" variant="secondary" onClick={handleClose}>
                            Schließen
                        </Button>
                    </DialogFooter>
                </form>
            </DialogContent>
        </Dialog>
    );
};
