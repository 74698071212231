import { Footer as FooterImpl } from '@ui/patterns/Footer/';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

export const Footer = () => (
    <FooterImpl
        heading={{ children: 'Dein Feedback ist relevant!' }}
        link={{
            href: 'https://teams.microsoft.com/l/channel/19%3ACwlAC7zich_wBZ_6IsjuTqbHDn3zdYkwEsn_5uPDgBU1%40thread.tacv2/Kontakt-Anfragen?groupId=dcea91da-8c91-4ce1-96f8-9e8049638cb7&tenantId=b4518aa8-0d3e-4d10-bc77-4cd7dede3446&ngc=true&allowXTenantAccess=true',
            children: (
                <>
                    <MailOutlineIcon style={{ marginRight: '8px' }} />
                    Kontakt
                </>
            ),
        }}
        text={{
            children:
                'Fragen oder Feedback? Hilf uns die transdev-AI-platform besser' + '                    zu machen',
        }}
        copyrightText={{
            children: <></>,
        }}
        version={{ children: '3.0.5' }}
    />
);
