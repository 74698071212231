'use client';
import { Bar, BarChart, CartesianGrid, XAxis } from 'recharts';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card';
import {
    ChartConfig,
    ChartContainer,
    ChartLegend,
    ChartLegendContent,
    ChartTooltip,
    ChartTooltipContent,
} from '@/components/ui/chart';
import { useCostYTD } from '../../context/useCostYDT';
import { monthNames } from '@/lib/utils';

const chartConfig = {
    iTokens: {
        label: 'prompt Tokens',
        color: 'hsl(var(--chart-1))',
    },
    oTokens: {
        label: 'completion Tokens',
        color: 'hsl(var(--chart-2))',
    },
} satisfies ChartConfig;

export function CostChart() {
    const data = useCostYTD().costYTDData;
    const chartData = data.map((item) => {
        return {
            month: monthNames[item.month],
            iTokens: item.inputCost?.toFixed(2) || 0,
            oTokens: item.outputCost?.toFixed(2) || 0,
        };
    }, []);

    return (
        <Card>
            <CardHeader>
                <CardTitle>Kosten</CardTitle>
                <CardDescription>2024 - Preis in EUR</CardDescription>
            </CardHeader>
            <CardContent>
                <ChartContainer config={chartConfig} className="min-h-[375px] w-full">
                    <BarChart accessibilityLayer data={chartData}>
                        <CartesianGrid vertical={false} />
                        <XAxis
                            dataKey="month"
                            tickLine={false}
                            tickMargin={10}
                            axisLine={false}
                            tickFormatter={(value) => value.slice(0, 3)}
                        />
                        <ChartTooltip content={<ChartTooltipContent hideLabel />} />
                        <ChartLegend content={<ChartLegendContent />} />
                        <Bar dataKey="oTokens" stackId="a" fill="var(--color-oTokens)" radius={[0, 0, 4, 4]} unit="€" />
                        <Bar dataKey="iTokens" stackId="a" fill="var(--color-iTokens)" radius={[4, 4, 0, 0]} />
                    </BarChart>
                </ChartContainer>
            </CardContent>
        </Card>
    );
}
